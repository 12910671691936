import React from "react";
import Fade from 'react-reveal/Fade';
import { useTranslation } from 'react-i18next';
import './css/Features.css';

import { SectionTitle } from './CommonsComponents'

export default function Features() {
  const { t } = useTranslation(); 

  let basicFeatures = [
    {
      icon: "fa fa-whatsapp", 
      title: t('features.socialMedia'),
      description: t('features.socialMediaDescription')
    },
    {
      icon: "fa fa-camera ", 
      title: t('features.liveCamera'), // Usa t('key') para traducir 
      description: t('features.liveCameraDescription')
    },
    {
      icon: "fa fa-microphone", 
      title: t('features.environmentRecording'),
      description: t('features.environmentRecordingDescription')
    },
    {
      icon: "fa fa-map-marker", 
      title: t('features.gpsLocation'),
      description: t('features.gpsLocationDescription')
    },
    {
      icon: "fa fa-phone", 
      title: t('features.callRecording'),
      description: t('features.callRecordingDescription')
    },
    {
      icon: "fa fa-desktop", 
      title: t('features.screenRecording'),
      description: t('features.screenRecordingDescription')
    },
    {
      icon: "fa fa-file-image-o", 
      title: t('features.multimediaFiles'),
      description: t('features.multimediaFilesDescription')
    },
    {
      icon: "fa fa-keyboard-o", 
      title: t('features.keyboardLogging'),
      description: t('features.keyboardLoggingDescription')
    },
    {
      icon: "fa fa-chrome", 
      title: t('features.browsingHistory'),
      description: t('features.browsingHistoryDescription')
    },
    {
      icon: "fa fa-eye-slash", 
      title: t('features.hiddenMode'),
      description: t('features.hiddenModeDescription')
    },
  ];

  return (
    <div id="features" className="container scrollMargin col-md-6 pb-4">
      <SectionTitle name={t('features.sectionTitle')} /> 

      {/* <div>{t('features.description')}</div> */}

      <div className="my-3 px-2 ">
        <div className="row ">
          <Fade>
            {basicFeatures.map((feature, index) => {
              return (
                // <Bounce left delay={index * 80}>
                <div className="col-md-6 my-2 " key={index}>
                  <div className="card blackGradient blueShadow grayBorder" >
                    <div className="card-body">
                   
                      <div className="text-center mb-2"> <i style={{ fontSize: "55px" }} className={feature.icon + ' text-success mb-1'} /></div>

                      <h6 className="card-title text-center mb-2"> <span className="text-light font-weight-bolder">{feature.title}</span></h6>
                      {/* <hr className="bg-secondary mb-1"  style={{width:"250px"}}/> */}
                      <p className="card-text" style={{fontSize:"13px" }}>{feature.description}</p>
                    </div>
                  </div>
                </div>
                // </Bounce>
              )
            })}
          </Fade>
        </div>
      </div>
    </div>
  );
}